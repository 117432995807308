<template>
  <div class="545error">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./ErrorMessage" class="page_link">Error Message</router-link> > 545 Auuthentication Internal Error
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What is "545 Authentication Internal Error"?</h1>
      <img alt="Vue logo" src="../../assets/545_authentication_internal_error.png">
      <h3>Answer</h3>
      <p class="answer">
        This error is caused by some problems SUZUKI SSO System's authentication.<br />
        ex, SAML authentication data is wrong or disable authentication function of SUZUKI SSO system.<br />
        Please contact SUZUKI's Support helpdesk.<br />
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      <router-link to="./540error"><img id="back" src="/arrow_back.png" v-on:mouseover="imageBackChange" v-on:mouseleave="imageBackReturn" /></router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="./552error"><img id="forward" src="/arrow_forward.png" v-on:mouseover="imageForwardChange" v-on:mouseleave="imageForwardReturn"/></router-link>
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
